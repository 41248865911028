<template>
  <div>
    <PageTitle>Feedback</PageTitle>

    <template v-if="state === 'submitted'"> Thank you for your feedback </template>

    <template v-else-if="state === 'submitting'">
      <Loading label="Submitting" />
    </template>

    <template v-else-if="state === 'error'">
      <ErrorAlert>Failed to submit feedback: {{ error }}</ErrorAlert>
    </template>

    <template v-else>
      <form class="form" @submit.prevent="submit">
        <div class="form-group">
          <label>
            Message
            <textarea class="form-control" v-model="form.content" required />
          </label>
        </div>

        <div class="form-group" v-if="false">
          <label>
            Rating <span class="text-muted">(optional)</span>
            <BFormRating v-model="form.rating" />
          </label>
        </div>

        <div class="btn-group">
          <button type="submit" class="btn btn-outline-primary">Submit</button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { BFormRating } from 'bootstrap-vue'

import { submitFeedback } from '../api/feedback.js'
import axios from '../axios.js'
import ErrorAlert from '../components/generic/ErrorAlert.vue'
import Loading from '../components/generic/Loading.vue'
import PageTitle from '../components/generic/PageTitle.vue'

export default {
  name: 'Feedback',
  components: {
    ErrorAlert,
    Loading,
    BFormRating,
    PageTitle,
  },
  data: () => ({
    error: null,
    form: {
      content: '',
      rating: null,
    },
    state: null,
  }),
  methods: {
    async submit() {
      this.state = 'submitting'
      try {
        this.error = null
        await submitFeedback(this.form)
        this.state = 'submitted'
      } catch (e) {
        this.error = axios.extractErrorMessage(e)
        console.error('Failed to submit user feedback', this.error, e)
        Sentry.captureException(e)
        this.state = 'error'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  max-width: 20em;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  .form-group {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
  }
}
</style>
