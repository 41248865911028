import axios from '../axios.js'

export const submitFeedback = async ({ content, rating }) => {
  if (rating == null) {
    rating = -1
  }
  const payload = {
    content,
    rating,
  }
  return axios.post('/users/v2/feedbacks', payload)
}
